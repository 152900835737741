import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {FeeDetailsError, RateCheckFeeDetails} from '../../_model/rate-check-v4.model';
import {TotalFeeBreakdownRcData} from '../../_model/custom/total-fee-breakdown.model';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {ZenBaseWithTranslateComponent} from '../zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';
import {MatrixPricingHelperService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';


@Component({
  selector: 'app-zen-total-fee-breakdown-popper-icon',
  templateUrl: './zen-total-fee-breakdown-popper-icon.component.html',
  styleUrl: './zen-total-fee-breakdown-popper-icon.component.scss'
})
export class ZenTotalFeeBreakdownPopperIconComponent extends ZenBaseWithTranslateComponent implements OnInit {
  @Input() feeDetails: RateCheckFeeDetails;
  @Input() feeError: FeeDetailsError = {};
  @Input() rateCheckData: TotalFeeBreakdownRcData;
  @Output() onSubmit = new EventEmitter<RateCheckFeeDetails>();

  platformUnit: ZenUnitsEnum
  rateCheckUnit: ZenUnitsEnum;
  ZenIcons = ZenIconsEnum;
  ZenNumberFormat = ZenNumberFormat;
  isElectric: boolean;

  platformMultiplier: number;

  constructor(
    public orgMgtSvc: OrganizationManagementService,
    public translateSvc: TranslateService,
    public mpHelpSvc: MatrixPricingHelperService
  ) {
    super(translateSvc);
  }

  ngOnInit() {
    this.isElectric = this.rateCheckData?.commodity === CommodityType.Electricity;
    this.platformUnit = this.isElectric ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth;
    this.rateCheckUnit = this.isElectric ? ZenUnitsEnum.MWh : this.rateCheckData?.rateCheckUnit.unit;

    this.platformMultiplier = this.isElectric ? .001 : this.rateCheckData?.rateCheckUnit.fromPlatformMultiplier;
  }

}
